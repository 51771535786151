<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Of all the unknowns, which compounds can be separated from the unknown mixture by extraction
        with
        <chemical-latex content="NaOH" />
        solution?
      </p>

      <p v-for="option in optionsShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LB_A8Q2B',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: '$p\\text{-Acetophetidide}$', value: 'pAcetophetidide'},
        {text: '$\\text{benzhydrol}$', value: 'benzhydrol'},
        {text: '$\\text{biphenyl}$', value: 'biphenyl'},
        {text: "$\\text{2'-bromoacetanilide}$", value: '2Bromoacetanilide'},
        {text: "$\\text{4'-bromoacetanilide}$", value: '4Bromoacetanilide'},
        {text: '$\\text{caffeine}$', value: 'caffeine'},
        {text: '$\\text{phenylacetic acid}$', value: 'phenylaceticAcid'},
        {text: '$\\text{propyl 4-hydroxybenzoate}$', value: 'propyl4Hydroxybenzoate'},
        {text: '$\\text{triphenylmethane}$', value: 'triphenylmethane'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
